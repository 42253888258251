import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

gsap.fromTo(
  "#js-zoom",
  { width: "80%" },

  {
    width: "100%",
    filter: "brightness(0.3) grayscale(1)",

    scrollTrigger: {
      trigger: "#js-zoom",
      start: "200px center",
      end: "bottom center",
      scrub: 0.6,
    },
  }
);

gsap.to(".l-header-nav-pc__list-link", {
  scrollTrigger: {
    trigger: ".p-top__parallax",
    start: "150px top",
    end: "bottom top",
    scrub: 0.1,
    toggleClass: {
      targets: ".l-header",
      className: "js-add-nav",
    },
  },
});

gsap.to(".l-header-nav-pc__list-link", {
  scrollTrigger: {
    trigger: ".p-top-method",
    start: "top top",
    end: "bottom top",
    scrub: 0.1,
    toggleClass: {
      targets: ".l-header",
      className: "js-add-nav",
    },
  },
});

gsap.to(".p-top-fv__scroll", {
  display: "flex",
  scrollTrigger: {
    trigger: ".p-top__parallax",
    start: "top center",
    end: "center center",
    toggleActions: "play reverse play reverse",
  },
});
