const center = document.querySelector("#center");
const leftBottom = document.querySelector("#leftBottom");
const leftTop = document.querySelector("#leftTop");
const rightTop = document.querySelector("#rightTop");
const rightBottom = document.querySelector("#rightBottom");

// 配列に表示させたい画像を入れる
const imageArray = [
  `${path}/img/top/therapist1.jpg`,
  `${path}/img/top/therapist2.jpg`,
  `${path}/img/top/therapist3.jpg`,
  `${path}/img/top/therapist4.jpg`,
  `${path}/img/top/therapist5.jpg`,
  `${path}/img/top/therapist6.jpg`,
  `${path}/img/top/therapist7.jpg`,
  `${path}/img/top/therapist8.jpg`,
  `${path}/img/top/therapist9.jpg`,
  `${path}/img/top/therapist10.jpg`,
];

let previousRandoms = null;

function createElement() {
  // 5回 配列からランダムに値を取り出す
  let randoms = [];

  while (randoms.length < 5) {
    let i = randoms.length;
    let randomIndex = imageArray[Math.floor(Math.random() * imageArray.length)];
    if (previousRandoms !== null) {
      if (previousRandoms[i] !== randomIndex) {
        if (!randoms.includes(randomIndex)) {
          randoms.push(randomIndex);
        }
      }
    } else {
      if (!randoms.includes(randomIndex)) {
        randoms.push(randomIndex);
      }
    }
  }

  // imgを生成
  const centerImg = document.createElement("img");
  const leftBottomImg = document.createElement("img");
  const leftTopImg = document.createElement("img");
  const rightTopImg = document.createElement("img");
  const rightBottomImg = document.createElement("img");

  // srcにランダムで取得した値を追加
  centerImg.src = randoms[0];
  leftBottomImg.src = randoms[1];
  leftTopImg.src = randoms[2];
  rightTopImg.src = randoms[3];
  rightBottomImg.src = randoms[4];

  // 要素にimgを追加
  center.prepend(centerImg);
  leftBottom.prepend(leftBottomImg);
  leftTop.prepend(leftTopImg);
  rightTop.prepend(rightTopImg);
  rightBottom.prepend(rightBottomImg);

  // 要素の一番最初の子要素にclassを追加
  center.firstChild.classList.add("js-add-random-front");
  leftBottom.firstChild.classList.add("js-add-random-front");
  leftTop.firstChild.classList.add("js-add-random-front");
  rightTop.firstChild.classList.add("js-add-random-front");
  rightBottom.firstChild.classList.add("js-add-random-front");

  previousRandoms = randoms;
}

function remove() {
  center.lastChild.remove();
  leftBottom.lastChild.remove();
  leftTop.lastChild.remove();
  rightTop.lastChild.remove();
  rightBottom.lastChild.remove();
}

// 読み込んだら発火
window.addEventListener("load", () => {
  createElement();
});

// ◯秒毎に要素を追加
window.setInterval(createElement, 5000);

// 同時だと消える、0.5秒遅らすとずれて増えるので、0.5秒遅くスタート
setTimeout(() => {
  window.setInterval(remove, 5000);
}, 1000);
