// Swiper
import Swiper, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css/bundle";
Swiper.use([Navigation, Pagination, Autoplay]);

const numberOfSlides = document.querySelectorAll(".swiper-slide").length;
const breakpoint = 768;

const shouldLoop =
  window.innerWidth > breakpoint ? numberOfSlides >= 4 : numberOfSlides >= 2;

console.log("Should loop:", shouldLoop);

const swiper = new Swiper(".column-swiper", {
  loop: shouldLoop,
  spaceBetween: 16,
  centeredSlides: true,

  autoplay: {
    delay: 1,
  },
  speed: 10000,

  breakpoints: {
    460: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    937: {
      slidesPerView: 3,
    },
  },

  pagination: {
    el: ".swiper-pagination",
    type: "fraction",

    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },

  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: true,
  },
});
