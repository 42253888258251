import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger.refresh(), ScrollToPlugin);

gsap.fromTo(
  "#therapists",
  { width: "80%" },

  {
    width: "100%",

    scrollTrigger: {
      trigger: ".p-top-therapists",
      start: "top center",
      end: "bottom center",
      scrub: 2,
    },
  }
);

gsap.fromTo(
  ".p-top-therapists__img-center",
  { transform: "translateY(-30px)" },

  {
    transform: "translateY(0px)",

    scrollTrigger: {
      trigger: ".p-top-therapists",
      start: "top center",
      end: "bottom center",
      scrub: 2,
    },
  }
);
